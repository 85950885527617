import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import size from 'lodash/size';

import { PayInternalInvoiceFormData } from '../../PayInternalInvoiceForm.types';
import {
  InvoiceID,
  InvoicePaymentMethods,
  PayInternalInvoiceCacheKeys
} from '../../../../../invoicesTypes';

import { payInternalInvoiceFormSchema } from './usePayInternalInvoiceForm.schema';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import {
  PayInternalInvoiceInput,
  usePayInternalInvoice
} from '../../../../../hooks/usePayInternalInvoice';
import { InvoicesPermissions } from '../../../../../invoicesConstants';

interface PayInternalInvoiceFormProps {
  invoiceIds: InvoiceID[];
  cacheKeys: PayInternalInvoiceCacheKeys;
}

const defaultBillingInfoValues: PayInternalInvoiceFormData = {
  accountId: ''
};

function usePayInternalInvoiceForm({
  invoiceIds,
  cacheKeys
}: PayInternalInvoiceFormProps) {
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();

  const { control, handleSubmitReactHookForm, resetForm } =
    useReactHookForm<PayInternalInvoiceFormData>({
      defaultValues: defaultBillingInfoValues,
      isModalForm: true,
      schema: payInternalInvoiceFormSchema
    });

  const {
    payInternalInvoiceLoading,
    payInternalInvoice,
    payInternalInvoiceErrorMessage
  } = usePayInternalInvoice({});

  const handlePayInternal = useCallback<
    (invoice: PayInternalInvoiceInput['invoice']) => Promise<void>
  >(
    (invoice) =>
      Promise.all(
        invoiceIds.map((invoiceId) =>
          payInternalInvoice({
            async: currentUser.hasPermissions(
              InvoicesPermissions.READ_PAY_INTERNAL_AS_ASYNC
            ),
            invoiceId,
            invoice
          })
        )
      ).then(() => {
        if (size(cacheKeys) > 0) {
          cacheKeys.map((eachCacheKey) =>
            queryClient.invalidateQueries(eachCacheKey)
          );
        }
      }),
    [cacheKeys, currentUser, invoiceIds, payInternalInvoice, queryClient]
  );

  return {
    control,
    payInternalInvoiceLoading,
    payInternalInvoiceErrorMessage,
    resetPayInternalInvoiceForm: resetForm,
    handleSubmitReactHookForm,
    handlePayInternalInvoice: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: async (data) =>
        handlePayInternal({
          accountId: data.accountId,
          paymentMethod: InvoicePaymentMethods.WIRE
        })
    })
  };
}

export default usePayInternalInvoiceForm;
