import { useCallback } from 'react';

import { BffApiUpdateItemError } from '../../../../types';
import {
  InvoiceID,
  InvoicePaymentMethods,
  PayInternalInvoiceCacheKeys
} from '../../invoicesTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { InvoiceBffUrl } from '../../InvoiceBffUrl';
import { AccountID } from '../../../accounts/accountsTypes';

interface PayInternalInvoiceOptions {
  cacheKeys?: PayInternalInvoiceCacheKeys;
}

export type PayInternalInvoiceInputData = {
  actionOptions: {
    url: string;
  };
};

export type PayInternalInvoiceInput = {
  async?: boolean;
  invoiceId: InvoiceID;
  invoice: {
    accountId: AccountID;
    paymentMethod: InvoicePaymentMethods;
  };
};

export type PayInternalInvoiceError = BffApiUpdateItemError;

function usePayInternalInvoice<PayInternalInvoiceResponseType>({
  cacheKeys
}: PayInternalInvoiceOptions) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    PayInternalInvoiceInputData & Pick<PayInternalInvoiceInput, 'invoice'>,
    PayInternalInvoiceResponseType,
    PayInternalInvoiceError
  >({ cacheKeys });

  const payInternalInvoice = useCallback<
    (input: PayInternalInvoiceInput) => Promise<unknown>
  >(
    ({ invoiceId, invoice, async }) =>
      postQuery({
        invoice,
        actionOptions: {
          url: async
            ? InvoiceBffUrl.payInternalAsync(invoiceId)
            : InvoiceBffUrl.payInternal(invoiceId)
        }
      }),
    [postQuery]
  );

  return {
    payInternalInvoiceData: postQueryData,
    payInternalInvoiceError: postQueryError,
    payInternalInvoiceLoading: postQueryLoading,
    payInternalInvoiceErrorMessage: postQueryErrorMessage,
    payInternalInvoice,
    payInternalInvoiceReset: postQueryReset
  };
}

export default usePayInternalInvoice;
