import React, { memo } from 'react';

import { ClassName, TableViewType } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { IndexTableCell } from '../IndexTableCell';

import { ContentWithTooltip } from '../../../../../helpers/ContentWithTooltip';
import { DateHelper } from '../../../../../helpers/DateHelper';
import { DateTimeHelper } from '../../../../../helpers/DateTimeHelper';
import { Icon } from '../../../../../helpers/Icon';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { getCustomDateFormat } from '../../../../../utils/getCustomDateFormat';

interface IndexTableCellDateTimeTooltipProps {
  burning?: boolean;
  customFormat?: string;
  date: string;
  divClassName?: ClassName;
  nullValue?: string;
  tdClassName?: ClassName;
  viewType?: TableViewType;
}

function IndexTableCellDateTimeTooltip({
  burning,
  customFormat,
  date,
  divClassName,
  nullValue,
  tdClassName,
  viewType
}: IndexTableCellDateTimeTooltipProps) {
  return (
    <IndexTableCell
      divClassName={divClassName}
      viewType={viewType}
      tdClassName={tdClassName}
    >
      <ContentWithTooltip
        withArrow
        tooltipPlacement={TooltipPlacement.LEFT}
        tooltipChildren={<DateTimeHelper date={date} />}
      >
        <DateHelper
          customFormat={customFormat || getCustomDateFormat(date)}
          date={date}
          nullValue={nullValue}
        />
        {burning ? <Icon icon={IconsEnum.BURNING} /> : null}
      </ContentWithTooltip>
    </IndexTableCell>
  );
}

export default memo<IndexTableCellDateTimeTooltipProps>(
  IndexTableCellDateTimeTooltip
);
