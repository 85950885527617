import { gql } from 'graphql-request';

import {
  SmartContractID,
  SmartContractNanoID,
  SmartContractUUID,
  SmartContractItemUpdatedAt,
  SmartContractItemID,
  SmartContractItemNanoID,
  SmartContractItemUUID,
  SmartContractItemPrice,
  SmartContractItemPrepayment,
  SmartContractItemItemTypeName,
  SmartContractItemItemCategoryName,
  SmartContractItemDescription,
  SmartContractItemUnbindedAt,
  SmartContractItemInvoiceID,
  SmartContractItemInvoiceUUID,
  SmartContractItemInvoiceNanoID,
  SmartContractItemInvoiceStatus,
  SmartContractItemInvoiceInvoiceType,
  SmartContractItemInvoiceParentInvoiceID,
  SmartContractItemInvoiceAmount,
  SmartContractItemInvoiceReturnReason,
  SmartContractItemShowVoidButton,
  SmartContractShowUnbindButton,
  SmartContractStatus,
  SmartContractShowCancelButton,
  SmartContractDisabledCancelButton,
  SmartContractShareStrategy,
  SmartContractShareStrategyValue,
  SmartContractInternalAt,
  SmartContractRuleID,
  SmartContractRuleNanoID,
  SmartContractRuleRuleType,
  SmartContractRulePresenceType,
  SmartContractRuleCreatedAt,
  SmartContractRuleValue,
  SmartContractRuleShareOverrideAt,
  SmartContractRuleName,
  SmartContractRuleFeeTypes,
  SmartContractRuleCompetencyContractorNanoID,
  SmartContractShowTransactionFeeButton,
  SmartContractDisabledTransactionFeeButton,
  SmartContractShowProvisionForBadDebtButton,
  SmartContractDisabledProvisionForBadDebtButton,
  SmartContractShowProvisionForRevisionButton,
  SmartContractDisabledProvisionForRevisionButton,
  SmartContractShowAvShareSchemeButton,
  SmartContractDisabledAvShareSchemeButton,
  SmartContractShowInternalSmartContractButton,
  SmartContractDisabledInternalSmartContractButton,
  SmartContractInvoiceID,
  SmartContractInvoiceStatus,
  SmartContractInvoiceInvoiceType,
  SmartContractInvoiceAmount,
  SmartContractInvoicePaymentMethod,
  SmartContractInvoicePrimaryInvoiceID,
  SmartContractInvoicePrepayment,
  SmartContractInvoiceClientId,
  SmartContractInvoiceParentInvoiceID,
  SmartContractInvoiceGeneralLedgerID,
  SmartContractShowChangeUserShareButton,
  SmartContractDisabledChangeUserShareButton,
  SmartContractShowRemoveFromContractButton,
  SmartContractDisabledRemoveFromContractButton,
  SmartContractShowCheckInButton,
  SmartContractDisabledCheckInButton,
  SmartContractShowPayFromAvContractorsInvoiceButton,
  SmartContractDisabledPayFromAvContractorsInvoiceButton,
  SmartContractShowPayFromPfrContractorsInvoiceButton,
  SmartContractDisabledPayFromPfrContractorsInvoiceButton,
  SmartContractShowPayFromPfbdContractorsInvoiceButton,
  SmartContractDisabledPayFromPfbdContractorsInvoiceButton,
  SmartContractRuleCompetencyContractorName,
  SmartContractRuleCompetencyContractorID,
  SmartContractRuleCompetencyContractorSurName,
  SmartContractRuleContractorCompetencyID,
  SmartContractInvoiceNanoID,
  SmartContractInvoiceUUID,
  SmartContractContractorsAmountAmount,
  SmartContractContractorsAmountContractorCompetencyID,
  SmartContractShowAddItemButton,
  SmartContractDisabledAddItemButton,
  SmartContractDisabledUnbindButton,
  SmartContractShowMoveItemButton,
  SmartContractDisabledMoveItemButton,
  SmartContractTaskShowStartButton,
  SmartContractTaskDisabledStartButton,
  SmartContractRuleCompetencyContractorCurrentTeamNanoID,
  SmartContractRuleCompetencyContractorImageFile,
  SmartContractItemInvoiceCreatedAt,
  SmartContractRuleCompetencyContractorFullName,
  SmartContractSmartContractType,
  SmartContractInvoiceGeneralLedgerName,
  SmartContractInvoiceGeneralLedgerGeneralLedgerType,
  SmartContractTaskProjectCompanyID,
  SmartContractTaskProjectCompanyNanoID,
  SmartContractCompanySmartContractTemplateName,
  SmartContractTaskProjectCompanyAutoPaymentScAt,
  SmartContractCompanyGeneralLedgerID,
  SmartContractInvoiceSelectedCurrency,
  SmartContractShowAutomaticPaymentButton,
  SmartContractItemViewSplitPartNumber,
  SmartContractItemViewSplitPartPercent
} from '../smartContractsTypes';
import {
  SmartContractShareCreatedAt,
  SmartContractShareGeneralLedgerCompanyID,
  SmartContractShareGeneralLedgerCompanyImageFile,
  SmartContractShareGeneralLedgerCompanyName,
  SmartContractShareGeneralLedgerCompanyNanoID,
  SmartContractShareGeneralLedgerCompanyOwnerClient,
  SmartContractShareGeneralLedgerCompanyOwnerFullName,
  SmartContractShareGeneralLedgerCompanyOwnerID,
  SmartContractShareGeneralLedgerCompanyOwnerImageFile,
  SmartContractShareGeneralLedgerCompanyOwnerNanoID,
  SmartContractShareGeneralLedgerID,
  SmartContractShareGeneralLedgerName,
  SmartContractShareID,
  SmartContractShareIteration,
  SmartContractShareShare,
  SmartContractShareShareType,
  SmartContractShareSmartContractID,
  SmartContractShareStatus,
  SmartContractShareUUID,
  SmartContractShareUpdatedAt,
  SmartContractShareUserFullName,
  SmartContractShareUserID,
  SmartContractShareUserName,
  SmartContractShareUserNanoID,
  SmartContractShareUserSurname,
  SmartContractShareUserUUID
} from '../../smartContractShares/smartContractSharesTypes';

export interface FetchTaskSmartContractQueryResponse {
  id: SmartContractID;
  uuid: SmartContractUUID;
  nanoId: SmartContractNanoID;
  status: SmartContractStatus;
  shareStrategy: SmartContractShareStrategy;
  shareStrategyValue: SmartContractShareStrategyValue;
  smartContractType: SmartContractSmartContractType;
  companySmartContractTemplateName: SmartContractCompanySmartContractTemplateName;
  internalAt: SmartContractInternalAt;
  company: {
    id: SmartContractTaskProjectCompanyID;
    nanoId: SmartContractTaskProjectCompanyNanoID;
    generalLedgerId: SmartContractCompanyGeneralLedgerID;
    autoPaymentScAt: SmartContractTaskProjectCompanyAutoPaymentScAt;
  };
  contractorsAmounts: {
    amount: SmartContractContractorsAmountAmount;
    contractorCompetencyId: SmartContractContractorsAmountContractorCompetencyID;
  }[];
  smartContractRules: {
    id: SmartContractRuleID;
    nanoId: SmartContractRuleNanoID;
    ruleType: SmartContractRuleRuleType;
    presenceType: SmartContractRulePresenceType;
    createdAt: SmartContractRuleCreatedAt;
    value: SmartContractRuleValue;
    shareOverrideAt: SmartContractRuleShareOverrideAt;
    competencyName: SmartContractRuleName;
    feeType: SmartContractRuleFeeTypes;
    contractorCompetencyId: SmartContractRuleContractorCompetencyID;
    competencyContractorId: SmartContractRuleCompetencyContractorID;
    competencyContractor: {
      id: SmartContractRuleCompetencyContractorID;
      nanoId: SmartContractRuleCompetencyContractorNanoID;
      name: SmartContractRuleCompetencyContractorName;
      surname: SmartContractRuleCompetencyContractorSurName;
      fullName: SmartContractRuleCompetencyContractorFullName;
      currentTeam: {
        nanoId: SmartContractRuleCompetencyContractorCurrentTeamNanoID;
      };
      image: {
        file: SmartContractRuleCompetencyContractorImageFile;
      };
    };
  }[];
  smartContractShares: {
    id: SmartContractShareID;
    uuid: SmartContractShareUUID;
    createdAt: SmartContractShareCreatedAt;
    updatedAt: SmartContractShareUpdatedAt;
    iteration: SmartContractShareIteration;
    status: SmartContractShareStatus;
    share: SmartContractShareShare;
    shareType: SmartContractShareShareType;
    smartContractId: SmartContractShareSmartContractID;
    generalLedgerId: SmartContractShareGeneralLedgerID;
    user: {
      id: SmartContractShareUserID;
      uuid: SmartContractShareUserUUID;
      nanoId: SmartContractShareUserNanoID;
      name: SmartContractShareUserName;
      surname: SmartContractShareUserSurname;
      fullName: SmartContractShareUserFullName;
    };
    generalLedger: {
      id: SmartContractShareGeneralLedgerID;
      name: SmartContractShareGeneralLedgerName;
      companyId: SmartContractShareGeneralLedgerCompanyID;
      company: {
        id: SmartContractShareGeneralLedgerCompanyID;
        nanoId: SmartContractShareGeneralLedgerCompanyNanoID;
        name: SmartContractShareGeneralLedgerCompanyName;
        image: {
          file: SmartContractShareGeneralLedgerCompanyImageFile;
        };
        owner: {
          id: SmartContractShareGeneralLedgerCompanyOwnerID;
          nanoId: SmartContractShareGeneralLedgerCompanyOwnerNanoID;
          client: SmartContractShareGeneralLedgerCompanyOwnerClient;
          fullName: SmartContractShareGeneralLedgerCompanyOwnerFullName;
          image: {
            file: SmartContractShareGeneralLedgerCompanyOwnerImageFile;
          };
        };
      };
    };
  }[];
  items: {
    updatedAt: SmartContractItemUpdatedAt;
    id: SmartContractItemID;
    nanoId: SmartContractItemNanoID;
    uuid: SmartContractItemUUID;
    price: SmartContractItemPrice;
    prepayment: SmartContractItemPrepayment;
    itemTypeName: SmartContractItemItemTypeName;
    itemCategoryName: SmartContractItemItemCategoryName;
    description: SmartContractItemDescription;
    unbindedAt: SmartContractItemUnbindedAt;
    invoices: {
      id: SmartContractItemInvoiceID;
      uuid: SmartContractItemInvoiceUUID;
      nanoId: SmartContractItemInvoiceNanoID;
      createdAt: SmartContractItemInvoiceCreatedAt;
      status: SmartContractItemInvoiceStatus;
      invoiceType: SmartContractItemInvoiceInvoiceType;
      parentInvoiceId: SmartContractItemInvoiceParentInvoiceID;
      amount: SmartContractItemInvoiceAmount;
      returnReason: SmartContractItemInvoiceReturnReason;
      generalLedger: {
        name: SmartContractInvoiceGeneralLedgerName;
        generalLedgerType: SmartContractInvoiceGeneralLedgerGeneralLedgerType;
      };
    }[];
    showVoidButton: SmartContractItemShowVoidButton;
    viewSplitPartNumber: SmartContractItemViewSplitPartNumber;
    viewSplitPartPercent: SmartContractItemViewSplitPartPercent;
  }[];
  invoices: {
    id: SmartContractInvoiceID;
    nanoId: SmartContractInvoiceNanoID;
    uuid: SmartContractInvoiceUUID;
    status: SmartContractInvoiceStatus;
    invoiceType: SmartContractInvoiceInvoiceType;
    amount: SmartContractInvoiceAmount;
    paymentMethod: SmartContractInvoicePaymentMethod;
    primaryInvoiceId: SmartContractInvoicePrimaryInvoiceID;
    prepayment: SmartContractInvoicePrepayment;
    clientId: SmartContractInvoiceClientId;
    parentInvoiceId: SmartContractInvoiceParentInvoiceID;
    generalLedgerId: SmartContractInvoiceGeneralLedgerID;
    smartContractRuleId: SmartContractRuleID;
    selectedCurrency: SmartContractInvoiceSelectedCurrency;
  }[];
  task: {
    showStartButton: SmartContractTaskShowStartButton;
    disabledStartButton: SmartContractTaskDisabledStartButton;
  };
  showUnbindButton: SmartContractShowUnbindButton;
  showCancelButton: SmartContractShowCancelButton;
  disabledCancelButton: SmartContractDisabledCancelButton;
  showTransactionFeeButton: SmartContractShowTransactionFeeButton;
  disabledTransactionFeeButton: SmartContractDisabledTransactionFeeButton;
  showProvisionForBadDebtButton: SmartContractShowProvisionForBadDebtButton;
  disabledProvisionForBadDebtButton: SmartContractDisabledProvisionForBadDebtButton;
  showProvisionForRevisionButton: SmartContractShowProvisionForRevisionButton;
  disabledProvisionForRevisionButton: SmartContractDisabledProvisionForRevisionButton;
  showAvShareSchemeButton: SmartContractShowAvShareSchemeButton;
  disabledAvShareSchemeButton: SmartContractDisabledAvShareSchemeButton;
  showInternalSmartContractButton: SmartContractShowInternalSmartContractButton;
  disabledInternalSmartContractButton: SmartContractDisabledInternalSmartContractButton;
  showChangeUserShareButton: SmartContractShowChangeUserShareButton;
  disabledChangeUserShareButton: SmartContractDisabledChangeUserShareButton;
  showRemoveFromContractButton: SmartContractShowRemoveFromContractButton;
  disabledRemoveFromContractButton: SmartContractDisabledRemoveFromContractButton;
  showCheckInButton: SmartContractShowCheckInButton;
  disabledCheckInButton: SmartContractDisabledCheckInButton;
  showPayFromAvContractorsInvoiceButton: SmartContractShowPayFromAvContractorsInvoiceButton;
  disabledPayFromAvContractorsInvoiceButton: SmartContractDisabledPayFromAvContractorsInvoiceButton;
  showPayFromPfrContractorsInvoiceButton: SmartContractShowPayFromPfrContractorsInvoiceButton;
  disabledPayFromPfrContractorsInvoiceButton: SmartContractDisabledPayFromPfrContractorsInvoiceButton;
  showPayFromPfbdContractorsInvoiceButton: SmartContractShowPayFromPfbdContractorsInvoiceButton;
  disabledPayFromPfbdContractorsInvoiceButton: SmartContractDisabledPayFromPfbdContractorsInvoiceButton;
  showAddItemButton: SmartContractShowAddItemButton;
  disabledAddItemButton: SmartContractDisabledAddItemButton;
  disabledUnbindButton: SmartContractDisabledUnbindButton;
  showMoveItemButton: SmartContractShowMoveItemButton;
  disabledMoveItemButton: SmartContractDisabledMoveItemButton;
  showAutomaticPaymentButton: SmartContractShowAutomaticPaymentButton;
}

export const FETCH_TASK_SMART_CONTRACT_QUERY = gql`
  query TaskSmartContract($uuid: ID!) {
    smartContract(uuid: $uuid) {
      id
      uuid
      nanoId
      status
      shareStrategy
      shareStrategyValue
      smartContractType
      companySmartContractTemplateName
      internalAt
      company {
        id
        nanoId
        generalLedgerId
        autoPaymentScAt
      }
      contractorsAmounts {
        amount
        contractorCompetencyId
      }
      smartContractRules {
        id
        nanoId
        ruleType
        presenceType
        createdAt
        value
        shareOverrideAt
        competencyName
        feeType
        competencyContractorId
        contractorCompetencyId
        competencyContractor {
          id
          nanoId
          name
          surname
          fullName
          providedFullName
          currentTeam {
            nanoId
          }
          image {
            file
          }
        }
      }
      smartContractShares {
        id
        uuid
        createdAt
        updatedAt
        iteration
        status
        share
        shareType
        smartContractId
        generalLedgerId
        user {
          id
          uuid
          nanoId
          name
          surname
          fullName
        }
        generalLedger {
          id
          name
          companyId
          company {
            id
            nanoId
            name
            image {
              file
            }
            owner {
              id
              nanoId
              client
              fullName
              image {
                file
              }
            }
          }
        }
      }
      items {
        updatedAt
        id
        nanoId
        uuid
        price
        prepayment
        itemTypeName
        itemCategoryName
        description
        unbindedAt
        invoices {
          id
          uuid
          nanoId
          createdAt
          status
          invoiceType
          parentInvoiceId
          amount
          returnReason
          generalLedger {
            name
            generalLedgerType
          }
        }
        showVoidButton
        viewSplitPartNumber
        viewSplitPartPercent
      }
      invoices {
        id
        nanoId
        uuid
        status
        invoiceType
        amount
        paymentMethod
        primaryInvoiceId
        prepayment
        clientId
        parentInvoiceId
        generalLedgerId
        smartContractRuleId
        selectedCurrency
      }
      task {
        showStartButton
        disabledStartButton
      }
      showUnbindButton
      showCancelButton
      disabledCancelButton
      showTransactionFeeButton
      disabledTransactionFeeButton
      showProvisionForBadDebtButton
      disabledProvisionForBadDebtButton
      showProvisionForRevisionButton
      disabledProvisionForRevisionButton
      showAvShareSchemeButton
      disabledAvShareSchemeButton
      showInternalSmartContractButton
      disabledInternalSmartContractButton
      showChangeUserShareButton
      disabledChangeUserShareButton
      showRemoveFromContractButton
      disabledRemoveFromContractButton
      showCheckInButton
      disabledCheckInButton
      showPayFromAvContractorsInvoiceButton
      disabledPayFromAvContractorsInvoiceButton
      showPayFromPfrContractorsInvoiceButton
      disabledPayFromPfrContractorsInvoiceButton
      showPayFromPfbdContractorsInvoiceButton
      disabledPayFromPfbdContractorsInvoiceButton
      showAddItemButton
      disabledAddItemButton
      disabledUnbindButton
      showMoveItemButton
      disabledMoveItemButton
      showAutomaticPaymentButton
    }
  }
`;
